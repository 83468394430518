import { format, parseISO, isValid, parse } from 'date-fns';

export function formatDate(data){
    if (!data)
        return data
    
    if(typeof data === 'string'){
        data = parseISO(data);
    }

    return format(data, 'dd/MM/yyyy');

}

export function dateFromString(dataString){
    if (!dataString)
        return dataString
    
    const dataFormatada = parse(dataString, 'dd/MM/yyyy', new Date());

    // Formate a data para o formato 'Y-m-d'
    const dataFinal = format(dataFormatada, 'yyyy-MM-dd');
    return dataFinal
}

export function formatDateTime(data){
    if (!data)
        return data

    if(typeof data === 'string'){
        data = parseISO(data);
    }

    return format(data, 'dd/MM/yyyy HH:mm:ss');

}

export function formatCurrency(valor, simbolo = '', decimais = 2){
    
    if (typeof valor === 'string') {
        const parsed = parseFloat(valor);
        if (isNaN(parsed)) {
            // Retorna o valor original se não for um número válido
            return valor;
        }
        valor = parsed;
    }

    // Verifica se o valor é um número após a conversão
    if (typeof valor !== 'number' || isNaN(valor)) {
        return valor;
    }

    // Formata o valor como moeda com o número de decimais
    return `${simbolo} ${valor.toFixed(decimais)
        .replace('.', ',') // Substitui ponto decimal por vírgula
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`.trim(); // Adiciona pontos para milhares
}


export function isData (dataString) {
    if(!dataString)
        return true

    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    if(regex.test(dataString)){
        const [dia, mes, ano] = dataString.split('/');
        dataString = `${ano}-${mes}-${dia}`
    }

    const data = parseISO(dataString);
    console.log(data, dataString, 'isData')
    return isValid(data)
}
