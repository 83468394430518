import { createApp } from 'vue';
import {mask} from 'vue-the-mask'
import App from '../App.vue';
import { formatDate } from '@/helpers/utils';
import { Money3Directive } from 'v-money3'


const app = createApp(App);
app.directive('mask', mask);
app.directive('money3', Money3Directive)

app.config.globalProperties.$formatDate = formatDate;


export default app;
